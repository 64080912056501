

.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 100px);
    background: url("../../assets/Background/background_contact.jpg") no-repeat;
    background-size: cover;
}

.form-title {
    margin-top: 120px;
    font-size: 50px;
    font-family: 'Bebas Neue', cursive;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
}



.vr {
    border-left: 2px solid gold;
    height: 70vh;
    margin: 10px auto;
    position:absolute;
    left: 50%;
    border-radius: 2px;
}

.form-container {
    position: relative;
    right: 25%;
    transform: translate(20%);
    background-color: rgba(32, 33, 36, 0.45);
    box-shadow: rgba(0, 0, 0, 0.09) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
    width: 40%;
    min-width: 600px;
    margin: 5% auto 10% auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
}

label {
    color: #999999;
    padding: 50px 0;
    font-size: 20px;

}

input {
    background-color: #F1F3F4;
    border: 0;
    height: 40px;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: large;
    font-family: 'Montserrat', sans-serif;
}

.input-form-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    grid-column: 1/2;
    grid-row: 1/2;
}

.input-form {
    margin-top:5% ;
    padding: 20px;
}

.input-form input {
    background-color: transparent;
    border-bottom: 1px solid white;
    border-radius: 0;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.input-form input:focus {
    outline: none;
    border-bottom: 1px solid gold;
    color: gold;
}

.textarea-form {
    padding: 11px;
    margin-top: 10px;
    grid-column: 2/3;
    grid-row: 1/2;

}

.textarea {
    resize: none;
    background-color: rgba(18, 18, 18, 0);
    border: none;
    width: 95%;
    margin: auto;
    font-size: 18px;
    box-sizing: border-box;
    color: white;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
}

textarea:focus {
    outline: 1px solid gold;
}

.btn-contact {
    background-color: gold;
    color: black;
    font-size: large;
    display: block;
    margin: 30px auto;
    width: 300px;
    height: 40px;
    border-radius: 20px;
}

.btn-contact:hover {
    cursor: pointer;
}

.btn-container {
    width: 90%;
    height: min-content;
    grid-column: 1/3;   
    grid-row: 2/3;
}

@media screen and (max-width: 600px) {

    .container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-x: hidden;
        height: calc(100vh - 100px);
        background: #121212;


    }
    .form-title{
        margin-top: 30px;
    }
    .btn-container {
        width: 50%;
        height: min-content;
        grid-column: 1/3;
        grid-row: 1/3;
    }
    .btn-contact {
        background-color: gold;
        color: black;
        font-size: large;
        display: block;
        margin: 5% 0 0 0;
        height: 40px;
        border-radius: 20px;
        width: 90%;
    }
    .input-form-container {
        width: 90%;
        display: flex;
        align-items: flex-start;
        padding-left: 50vw;
    }
    label{
        padding: 10px;
    }
    .textarea-form {
        padding: 11px;
        margin-top: 10px;
        grid-column: 2/3;
        grid-row: 1/2;

    }

    .textarea{
        width: 90%;
        margin: auto;
        font-size: 18px;
        box-sizing: border-box;
        color: white;
        padding: 10px;
        font-family: 'Montserrat', sans-serif;
    }
    .input-form{
        padding: 0;
    }
    .form-container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #121212;
        width: 90vw;
        border-radius: 20px;
        padding: 5%;
        margin:0;
    }

}