
.thumbnail-image {
    width: 320px;
    margin: auto;
    transition: 0.3s;
    text-align: center;
}
.thumbnail {
    width: 320px;
    margin: auto;
    transition: 0.3s;
}
.thumbnail:hover .video-title {
    visibility: visible;
    opacity: 1;
}
.thumbnail-image:hover {
    filter: brightness(100%);
}
.video-title {
    padding:10px;
    position: relative;
    bottom:100px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    font-size: 15px;
    background: rgba(7, 7, 7, 0.45);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
}
.video-title:hover{
    display: block;
}
@media screen and (max-width: 830px) {
.thumbnail-image{
    width: 160px;
}
    .video-title{
        display: none;
    }
}