@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

* {
    margin: 0;
    padding: 0;
}

.nav-container {
    background-color: #121212;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100% !important;
    overflow: hidden;
}
.nav-container.contacto{
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(15, 15, 15))}
.nav-container.aboutus{
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(15, 15, 15))}
.active {
    position: absolute;
    z-index: 3;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)) !important;

}
.nv-logo-container{
    width: 10%;
    z-index:1;
}
.logo{
    margin-left: auto;
    max-width:80%;
}

.side-navbar {
    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 300px;
    z-index: 100;
    position: absolute;
    height: 100% !important;
    top: 0;
    left: -300px;
    transition: transform 1s;
}

.side-navbar-active {
    transform: translateX(300px);
}

.sidenav-item {
    color: #adadad;
    font-family: 'Bebas Neue', cursive;
    font-size: 25px;
    margin-top: 40px;
    margin-left: 40px;
}

.sidenav-item-active:hover {
    color: darkgoldenrod;
}

.sidenav-item-active {
    color: gold;
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
    margin-top: 40px;
    margin-left: 40px;
}

.nav-hamburger {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    width: 100% !important;
    background: #121212;
}

.nav-item {
    color: #d1d1d1;
    margin: 0 20px;
    font-family: 'Bebas Neue', cursive;
    font-size: 1.2vw;
    margin-block: 2px;
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.nav-item::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: gold;
    border-radius: 4px;
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform .25s ease;
}

.nav-item:hover::after {
    transform: scaleX(1);
}

.nav-item-active {
    color: gold;
    margin: 0 30px;
    font-family: 'Bebas Neue', cursive;
    font-size: 30px;
}

.nav-item:hover {
    cursor: pointer;
}

.nav-item-active:hover{
    color: darkgoldenrod;
    cursor: pointer;
}

.hamburger {
    background-color: transparent;
    border: none;
    margin-right: 40px;
    outline: none;
    cursor: pointer;
}

.hamburger .line {
    display: block;
    width: 40px;
    height: 3px;
    background-color: white;
    margin-block: 10px;
    border-radius: 4px;
    transition: transform .5s, opacity .25s;
}

.hamburger-active .line:nth-child(1){
    transform: translateY(13px) rotate(45deg);
}

.hamburger-active .line:nth-child(2){
    opacity: 0;
}

.hamburger-active .line:nth-child(3){
    transform: translateY(-13px) rotate(-45deg);
}
.icon {
    display: none;
}
@media screen and (max-width: 1000px) {
    a:not(:first-child) {display: none;}
     a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 1000px) {
    .responsive {position: relative;}
    .responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .responsive a {
        float: none;
        display: block;
        text-align: left;
    }
    .fa{
        display:none;
    }
}