@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');


.home-screen-container{
    height: 100%;
    width: 100%;
    background: url("../../assets/Background/IMG_20190425_193345.jpg") no-repeat;
    background-size: cover;
}
.image {
    position: fixed;
    z-index: -2;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    filter: brightness(100%);
    object-fit: cover;
}
.phone-image{
    position: fixed;
    z-index: -3;
    left: 0;
    bottom: 0;
    min-height: 100%;
    min-width: 100%;
    filter: brightness(100%);
    background-size: cover;
    width: 80%;
    display:none;
    object-fit: cover;

}
h1 {
    position: relative;
    top: 80px;
    display: flex;
    font-family: 'Bebas Neue', cursive;
    font-size: 200px;
    color: rgba(245, 245, 245, 0.41);
    justify-content: center;
    align-items: center;
}

h6 {
    position: relative;
    top: 80px;
    font-family: 'Bebas Neue', cursive;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(245, 245, 245, 0.44);
}
.subtitle{
    color: rgba(245, 245, 245, 0.4);
    font-size:60px;
}

.awssld {
    --organic-arrow-color: #fff;
}

.slider-item-container {
    z-index: 0;
}


.slider-item-content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 80vw;
}

.align-content-right {
    align-content: flex-end;
    align-items: flex-end;
}

.align-content-left {
    align-content: flex-start;
    align-items: flex-start;
}

.icons-container {
    position: relative;
    top: 80px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-item-title {
    font-family: 'Bebas Neue', cursive;
    font-size: 50px;
    text-align: center;
    display: block;
    color: white;
    margin-bottom: 20px;
}

.slider-item-button {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    overflow: hidden;
    transition: box-shadow 2s, color 2s, background-color 1s;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    outline: 2px solid white;
    font-family: 'Montserrat', sans-serif;
    padding: 15px;
    font-size: 25px;
    width: auto;
    margin: 20px 0;


}

.slider-item-button:hover {
    cursor: pointer;
    color: black;
    box-shadow: inset 10em 0 0.5em 0 white;
}


.icon-btn {
    color: grey;
    margin: 20px;
}
.fa-youtube:before {
    content: "\f167";
}
.fb:hover{
    color: royalblue;
    font-size: 20px;
}
.yt:hover{
    color: red;
    font-size:20px;
}
.ig:hover{
   color: rebeccapurple;
    font-size: 20px
}
@media screen and (max-width: 700px) {
    h1{
        font-size: 100px;
    }
    .slider-item-title{
        font-size: 50px;
    }

    .subtitle{
        font-size: 40px;
    }
    .slider-item-content{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slider-item-button{
        margin-top: 50px;
    }
    .slider-item-title {
        margin-bottom: 60px;

    }



}
