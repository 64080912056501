.text-container{
    min-width: 300px;
    width: 60%;
    height: 50%;
    padding: 3vw;
    background-color: rgba(17, 17, 17, 0.37);

}

.about-paragraph{
    margin-top: 10px;
    font-size: calc(12px + 1vw);
    color: whitesmoke;
    font-weight: 10;
    letter-spacing: 0.5px;
    text-align: justify;
}

.about-us-title{
    font-size: calc(25px + 1vw);
    color: #fff;
    font-weight: 10;
}
.about-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;
}
.image-container {
    position: absolute;
    right: 2vw;
    top: 20vh;
    min-width: 40vw;
    width: 150px;
    -webkit-filter: drop-shadow(5px 5px 5px #ffffff);
    filter: drop-shadow(3px 3px 3px #ffffff);

}
.about-screen-container{
    display: flex;
    flex-direction: column;
    background: url("../../assets/Background/background_contact.jpg") no-repeat;
    background-size: cover;
    height:calc(100vh - 100px);
    width: 100%;
}
.logos-display{
    height: 40%;
    width: 65%;
    padding: 2% 2% 2% 2%;
    display: flex;
    overflow: hidden;
}
.arrow-container{
    width: 2%;
    padding: 0 2% 0 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrow{
    width: 100%;
    height: 100%;
   color: white;
    cursor: pointer;
}
.arrow:hover {
    transform: scale(1.2);
}

.arrow.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.arrow.disabled:hover {
    transform: scale(1);
}

.logos-carousel {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10%;
    padding: 0 2% 0 2%;
    transition: transform 0.5s ;
    overflow: hidden;
    align-items: center;
}

.slide-in-back {
    animation: slide-in 1s forwards;
    animation-timing-function: cubic-bezier(0, 1.2, 1, 0.98);
    animation-name: slide-in-right;
}

.slide-in-forward {
    animation: slide-in 1s forwards;
    animation-timing-function: cubic-bezier(0, 1.2, 1, 0.98);
    animation-name: slide-in-left;
}

@keyframes slide-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.logo-container{
    height: 80%;
    padding: 2%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-item{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media screen and (max-width: 830px) {
    .image-container {
        display: none;
    }

    .text-container {
        width: 90vw;
        left: 2vw;
        box-shadow: 20px 20px 50px #1a1a1a;
        height: fit-content;
    }

    .about-screen-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-x: hidden;
        height: calc(100vh - 100px);
        background: #121212;
    }

    .about-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 99%;
        width: 100%;
    }
    .logos-display{
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: visible;
    }

    .logos-carousel {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10%;
        padding: 0 2% 0 2%;
        transition: transform 0.5s ;
        overflow: hidden;
        align-items: center;
    }

}




