.float{
    position:fixed;
    width:40px;
    height:40px;
    bottom:1%;
    right:1%;
    padding:10px;
    background-color:#25d366;
    color:#FFF;
    border-radius:40px;
    text-align:center;
    font-size:20px;
    z-index:100;
    box-shadow: 0 0 20px #25d366;
}
.float:hover {
    background: rgba(0,0,0,0);
    color: #25d366;
    box-shadow: inset 0 0 20px 3px;
}
