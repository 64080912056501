.category-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #121212;
    background-size: cover;
}

.category-title {
    margin: 5% 0 5% 0;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 50px;
  color: rgba(245, 245, 245, 0.87);
    font-family: 'Bebas Neue', cursive;
}

.selected-video {
    width: 70%;
    min-width: 350px;
    height: 600px;
    margin: auto;
    display: block;
    border: none;
}

.thumbnails-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin: 30px auto;
    width: 70%;
    grid-gap: 10px;
}
@media screen and (max-width: 830px) {
    .selected-video {
        display: flex;
        width: 100px;
        justify-content: center;
        height: 200px;
    }
    .thumbnails-grid{
        display: grid;
        justify-content: flex-start;
        align-items: flex-start;
        width: 90%;
       grid-template-columns: repeat(auto-fit,minmax(150px,0.5fr));

    }
    .category-page-container {
        height: 100vh;
        overflow-x: hidden;
    }
}